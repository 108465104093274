import { bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ProductionService } from 'services/production-service';
import { c } from 'common/common';

export class PolicyNumber {
    static inject = [EventAggregator, ProductionService];
    @bindable policy;
    @bindable showIcon = true;
    @bindable tab;
    _ea;
    _production;
    showMultiple = false;

    constructor(ea, production) {
        this._ea = ea;
        this._production = production;
    }

    async policyChanged() {
        if (!this.policy) return;
        this.policyId = this.policy.id;
        this.policyNumber = this.policy.policyNumber;
        if (!this.policyId) {
            try {
                this.showMultiple = false;
                const policies = await this._production.policyByNumber(this.policyNumber);
                if (!policies || !policies.length) return;
                if (policies.length === 1) {
                    this.policyId = policies[0].id;
                    return;
                }
                // More than 1...list them all with writing agents
                this.showMultiple = true;
                this.policies = policies;
            } catch (err) {
                console.log(err);
            }
        }
    }

    openPolicy(ev, p) {
        ev.stopPropagation();
        ev.preventDefault();
        const id = p ? p.id : this.policyId;
        this._ea.publish(c.EventKeys.site.openPolicy, { policyId: id, tab: this.tab });
    }
}
